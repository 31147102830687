<template>
  <div>
    <a-modal
      width="1000px"
      v-model="isShow"
      centered
      :mask-closable="false"
      title="项目详情"
    >
      <template slot="footer">
        <div>
          <a-space>
            <div>
              <a-button
                type="primary"
                @click="closeModal"
              >
                关闭
              </a-button>
            </div>
          </a-space>
        </div>
      </template>
      <a-spin :spinning="loading">
        <agent-self-purchase-item-basic-info :data="data" />

        <a-descriptions title="项目操作历史" :column="1" />
        <agent-self-purchase-item-event-list :item-id="id" />
      </a-spin>
    </a-modal>
  </div>
</template>

<script>
import { findAgentPurchaseItem } from '@/api/agent_purchase_item'
import AgentSelfPurchaseItemBasicInfo from '@/views/agent_purchase_items/show/BasicInfo'
import AgentSelfPurchaseItemEventList from '@/views/agent_purchase_item_events/index'

export default {
  name: 'ShowAgentSelfPurchaseItem',
  components: {
    AgentSelfPurchaseItemBasicInfo,
    AgentSelfPurchaseItemEventList
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    id: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      data: {},
      loading: true
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.loading = true
      findAgentPurchaseItem(this.id).then((res) => {
        if (res.code === 0) {
          this.data = res.data
        }
        this.loading = false
      })
    },

    closeModal() {
      this.isShow = false
    }
  }
}
</script>
